import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, ButtonSubmit } from './styles';
import api from '~/services/api';

import imgLogo from '~/assets/img/logo.png';
import { InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import history from '~/services/history';

const Recovery: React.FC = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { hash } = useParams<{ hash: string }>();
  const [type, setType] = useState<boolean>(true);

  const handleNewPassword = handleSubmit(async (formData) => {
    try {
      if (formData.password.trim() === '' || formData.cpassword.trim() === '') {
        toast.warn('Senha e Confirmação devem ser preenchidas.');
        return;
      }
      if (formData.password !== formData.cpassword) {
        toast.error('As senhas não correspondem!');
        return;
      }

      const { data } = await api.put('/recovery', {
        hash,
        password: formData.password,
      });

      if (data.success) {
        toast.success('Senha alterada com sucesso!');
        history.push('/');
      } else {
        toast.error(data.message);
      }
    } catch (error: any) {
      console.log(error.data.message);
    }
  });

  return (
    <Container>
      <div
        style={{
          width: 350,
          height: 408,
          display: 'felx',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgLogo}
            alt=""
            className="logo"
            style={{ width: '100px', height: '100px' }}
          />
        </div>
        <h6 className="my-3" style={{ textAlign: 'center' }}>
          Crie uma nova senha para prosseguir
        </h6>
        <InputText
          maxLength={50}
          label="Nova Senha"
          caseInput="upper"
          placeholder=""
          name="password"
          control={control}
          toLowerCase={false}
          register={register}
          isError={!!errors.password}
          type={type ? 'password' : 'text'}
        />
        <InputText
          maxLength={50}
          label="Confirme sua Senha"
          caseInput="upper"
          placeholder=""
          name="cpassword"
          control={control}
          toLowerCase={false}
          register={register}
          isError={!!errors.cpassword}
          type={type ? 'password' : 'text'}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '5px',
            marginTop: '5px',
          }}
        >
          <small
            style={{ color: '#6B778C', cursor: 'pointer' }}
            onClick={() => setType(!type)}
          >
            Visualizar senha
          </small>
        </div>
        <ButtonSubmit
          style={{ marginTop: '23px' }}
          onClick={() => handleNewPassword()}
        >
          Enviar
        </ButtonSubmit>
      </div>
    </Container>
  );
};

export default Recovery;
